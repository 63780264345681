body {
	background-color: #dde0e9;
}
.app {
	height: 100vh;
	display: flex;
}
.app .leftView,
.app .rightView {
	width: 50vw;
	overflow: auto;
}
.leftView > * {
	margin-top: 30px;
	background: white;
	width: 100%;
}

h3.caption,
caption h3 {
	text-align: left;
	background: #fff;
	box-shadow: 0px 0px 1px 0px black;
	margin: 0;
	width: 100%;
	padding: 11px;
	color: #004669;
	line-height: 22px;
	font-size: 20px;
	font-weight: 100;
	cursor: pointer;
}
h3.caption:hover,
caption h3:hover {
	background: #fafafa;
}
table {
	border-spacing: 0px;
	border-collapse: collapse;
}

table thead tr {
	background: #1a4198;
	color: white;
}
table thead tr th {
	padding: 5px;
}
table td,
table th {
	padding: 5px;
	box-shadow: inset 0px 0px 1px 0px black;
}
