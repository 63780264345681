.DisplayText {
	display: flex;
	flex-direction: column;
	padding: 5px 20px;
	min-width: fit-content;
	flex-grow: 1;
}

.DisplayText .label {
	color: #ff5e00;
	font-weight: bold;
	margin-bottom: 5px;
}

.DisplayText .value {
	color: #1a4891;
	padding-left: 5px;
}
.DisplayText span.value:empty:before {
	content: "-";
	content: "\200b";
}
