.CaseDetail {
	display: flex;
	flex-wrap: wrap;
}
.CaseDetail > .tile {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 2px;
	padding: 5px 20px;
	min-width: fit-content;
	box-shadow: inset -1px 0px 1px 0px #d1d1d1;
	flex-grow: 1;
	box-sizing: content-box;
}

.CaseDetail .fieldName {
	color: #ff5e00;
	font-weight: bold;
	margin-bottom: 5px;
}
.CaseDetail .fieldValue {
	color: #1a4891;
	padding-left: 5px;
}
