@layer module {
  .home {
    display: flex;
  }
  .home .left {
    flex-basis: 80%;
  }
  .home .right {
    flex-grow: 1;
  }
}
